import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/nodejs-install-with-nvm",
  "date": "2016-02-29",
  "title": "NODEJS INSTALL WITH NVM",
  "author": "admin",
  "tags": ["development", "javascript", "node.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "Node Version Manager is a simple bash script to manage multiple active node.js versions. In this article, we are going to discuss about managing NodeJS install with NVM."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why NVM?`}</h2>
    <p>{`At any given time, you could be working with different projects, some using the older version of NodeJS and other recent. NVM enables us to install different versions for different projects.`}</p>
    <h2>{`Installing NVM`}</h2>
    <p>{`You do have to have at least one version of node and npm to get started, but after that you can install nvm with npm.`}</p>
    <p>{`You can install NVM as a script using `}<inlineCode parentName="p">{`cURL`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`curl -o- https://raw.githubusercontent.com/creationix/nvm/v0.31.0/install.sh | bash
`}</code></pre>
    <p>{`Or you can also use `}<inlineCode parentName="p">{`Wget`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`wget -qO- https://raw.githubusercontent.com/creationix/nvm/v0.31.0/install.sh | bash
`}</code></pre>
    <p>{`You might have to change the version, check `}<a parentName="p" {...{
        "href": "https://github.com/creationix/nvm"
      }}>{`Github`}</a>{` for the latest version.`}</p>
    <Message type="info" title="" content="You might have to close and reopen your terminal to start using NVM." mdxType="Message" />
    <h2>{`Install Node using NVM`}</h2>
    <p>{`Let's look at how we can use NVM to install Node now. Before downloading a specific version of Node, you can find which versions are available for you to download.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`nvm ls-remote
`}</code></pre>
    <p>{`You can install the version you desire with the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`nvm install 5.0
`}</code></pre>
    <p>{`And then in any new shell just use the installed version:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`nvm use 5.0
`}</code></pre>
    <h2>{`Using NVM`}</h2>
    <p>{`You can check which version of NVM is installed with the following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`nvm ls
`}</code></pre>
    <p>{`If you are only using one version of Node, you can set it as `}<inlineCode parentName="p">{`default`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`nvm alias default 5.0
`}</code></pre>
    <p>{`When you are using multiple projects, you can tell which version to be used by creating `}<b>{`.nvmrc`}</b>{` file inside the directory.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Managing versions is crucial but tedious. The task should be automated. NVM helps us manage multiple Node Js versions without a sweat. It becomes much easy to work in a working version, and try something new in the latest version. All you have to do is `}<b>{`nvm use default`}</b>{` and `}<b>{`nvm use stable`}</b>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      